import * as React from 'react';
import {
    LabelSmall, LabelMedium
} from 'baseui/typography';
import { useStyletron } from 'baseui';

const svgStyle = {
    left: '50%',
    height: '24px',
    position: 'relative',
    transform: 'translateX(-50%)',
    marginBottom: '0px'
};

const StepV1 = ({ text, onClick, disabled, selected, index, svgName, totalSteps }) => {
    const [css, theme] = useStyletron();
    const background = selected ? theme.colors.primaryC : theme.colors.primaryB;
    const color = selected ? 'white' : theme.colors.primaryC;
    const textColor = selected ? 'white' : theme.colors.primaryA;
    return (
        <div
            onClick={() => {
                return disabled ? null : onClick()
            }}
            style={{
                width: '100px',
                height: '72px',
                background: selected ? background : 'none',
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.5 : 1,
                position: 'relative',
                float: 'left',
                boxSizing: 'border-box',
                borderRight: '2px solid #EEEEEE'
            }}>
            {svgName ? <>
                <div style={{
                    position: 'relative',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    {svgName == 'cover' && <svg style={svgStyle} width="17" height="20" viewBox="0 0 17 20" fill={color} xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 2V1C14 0.45 13.55 0 13 0H1C0.45 0 0 0.45 0 1V5C0 5.55 0.45 6 1 6H13C13.55 6 14 5.55 14 5V4H15V8H5V19C5 19.55 5.45 20 6 20H8C8.55 20 9 19.55 9 19V10H17V2H14Z" fill={color} />
                    </svg>
                    }
                    {svgName == 'filters' && <svg style={svgStyle} width="18" height="12" viewBox="0 0 18 12" fill={color} xmlns="http://www.w3.org/2000/svg">
                        <path d="M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z" fill={color} />
                    </svg>
                    }

                    {svgName == 'builder' && <svg style={svgStyle} width="18" height="20" viewBox="0 0 18 20" fill={color} xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 2C9.55 2 10 2.45 10 3C10 3.55 9.55 4 9 4C8.45 4 8 3.55 8 3C8 2.45 8.45 2 9 2ZM11 16H4V14H11V16ZM14 12H4V10H14V12ZM14 8H4V6H14V8Z" fill={color} />
                    </svg>
                    }

                    {svgName == 'preview' && <svg style={svgStyle} width="24" height="16" viewBox="0 0 24 16" fill={color} xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 2H22V0H4C2.9 0 2 0.9 2 2V13H0V16H14V13H4V2ZM23 4H17C16.45 4 16 4.45 16 5V15C16 15.55 16.45 16 17 16H23C23.55 16 24 15.55 24 15V5C24 4.45 23.55 4 23 4ZM22 13H18V6H22V13Z" fill={color} />
                    </svg>
                    }

                    {svgName == 'timing' && <svg style={svgStyle} width="21" height="14" viewBox="0 0 21 14" fill={color} xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4H0V6H12V4ZM12 0H0V2H12V0ZM0 10H8V8H0V10ZM19.5 5.5L21 7L14.01 14L9.5 9.5L11 8L14.01 11L19.5 5.5Z" fill={color} />
                    </svg>
                    }

                    <LabelMedium style={{ textAlign: 'center', color:textColor }}>{text}</LabelMedium>
                </div>
            </> :
                <div style={{
                    position: 'relative',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    {index == 0 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none" /><path d="M22 12l-4 4-1.41-1.41L18.17 13h-5.23c-.34 3.1-2.26 5.72-4.94 7.05C7.96 21.69 6.64 23 5 23c-1.66 0-3-1.34-3-3s1.34-3 3-3c.95 0 1.78.45 2.33 1.14 1.9-1.03 3.26-2.91 3.58-5.14h-3.1C7.4 14.16 6.3 15 5 15c-1.66 0-3-1.34-3-3s1.34-3 3-3c1.3 0 2.4.84 2.82 2h3.1c-.32-2.23-1.69-4.1-3.59-5.14C6.78 6.55 5.95 7 5 7 3.34 7 2 5.66 2 4s1.34-3 3-3c1.64 0 2.96 1.31 2.99 2.95 2.68 1.33 4.6 3.95 4.94 7.05h5.23l-1.58-1.59L18 8l4 4z" /></svg>}
                    {index == 1 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><circle cx="10" cy="8" r="4" /><path d="M10.67,13.02C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26 C10.47,18.87,10,17.49,10,16C10,14.93,10.25,13.93,10.67,13.02z" /><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" /></g></g></svg>}
                    {index == 2 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><rect fill="none" height="24" width="24" /><path d="M19,3H4.99C3.89,3,3,3.9,3,5l0.01,14c0,1.1,0.89,2,1.99,2h10l6-6V5C21,3.9,20.1,3,19,3z M7,8h10v2H7V8z M12,14H7v-2h5V14z M14,19.5V14h5.5L14,19.5z" /></svg>}
                    {index == 3 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" /></svg>}
                    {index == 4 && <svg style={svgStyle} width="21" height="14" viewBox="0 0 21 14" fill={color} xmlns="http://www.w3.org/2000/svg"><path d="M12 4H0V6H12V4ZM12 0H0V2H12V0ZM0 10H8V8H0V10ZM19.5 5.5L21 7L14.01 14L9.5 9.5L11 8L14.01 11L19.5 5.5Z" fill={color} /></svg>}
                    {index == 5 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M16.5 12.5H15v4l3 2 .75-1.23-2.25-1.52V12.5zM16 9L2 3v7l9 2-9 2v7l7.27-3.11C10.09 20.83 12.79 23 16 23c3.86 0 7-3.14 7-7s-3.14-7-7-7zm0 12c-2.75 0-4.98-2.22-5-4.97v-.07c.02-2.74 2.25-4.97 5-4.97 2.76 0 5 2.24 5 5S18.76 21 16 21z" /></svg>}
                    {index == 6 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><path d="M17,1.01L7,1C5.9,1,5,1.9,5,3v18c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V3C19,1.9,18.1,1.01,17,1.01z M17,18H7V6h10V18z M9.5,8.5H12V7H8v4h1.5V8.5z M12,17h4v-4h-1.5v2.5H12V17z" /></g></g></svg>}
                    <LabelSmall style={{ textAlign: 'center', color:textColor }}>{text}</LabelSmall>
                </div>
            }
        </div>
    )
};

const Step = (props) => {
    const { text, onClick, disabled, selected, index, svgName, version } = props;
    const [css, theme] = useStyletron();
    const background = selected ? theme.colors.primaryC : theme.colors.primaryB;
    const color = selected ? 'white' : theme.colors.primaryA;
    if (version) return <StepV1 {...props}></StepV1>
    return (
        <div
            onClick={() => {
                return disabled ? null : onClick()
            }}
            style={{
                width: '152px',
                height: '98px',
                background,
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                marginBottom: '18px',
                cursor: disabled ? 'not-allowed' : 'pointer',
                opacity: disabled ? 0.5 : 1,
            }}>
            {svgName ? <>
                <div style={{
                    position: 'relative',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    {svgName == 'cover' && <svg style={svgStyle} width="17" height="20" viewBox="0 0 17 20" fill={color} xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 2V1C14 0.45 13.55 0 13 0H1C0.45 0 0 0.45 0 1V5C0 5.55 0.45 6 1 6H13C13.55 6 14 5.55 14 5V4H15V8H5V19C5 19.55 5.45 20 6 20H8C8.55 20 9 19.55 9 19V10H17V2H14Z" fill={color} />
                    </svg>
                    }
                    {svgName == 'filters' && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none" /><path d="M22 12l-4 4-1.41-1.41L18.17 13h-5.23c-.34 3.1-2.26 5.72-4.94 7.05C7.96 21.69 6.64 23 5 23c-1.66 0-3-1.34-3-3s1.34-3 3-3c.95 0 1.78.45 2.33 1.14 1.9-1.03 3.26-2.91 3.58-5.14h-3.1C7.4 14.16 6.3 15 5 15c-1.66 0-3-1.34-3-3s1.34-3 3-3c1.3 0 2.4.84 2.82 2h3.1c-.32-2.23-1.69-4.1-3.59-5.14C6.78 6.55 5.95 7 5 7 3.34 7 2 5.66 2 4s1.34-3 3-3c1.64 0 2.96 1.31 2.99 2.95 2.68 1.33 4.6 3.95 4.94 7.05h5.23l-1.58-1.59L18 8l4 4z" /></svg>}
                    {svgName == 'builder' && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" /></svg>}
                    {svgName == 'preview' && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><path d="M17,1.01L7,1C5.9,1,5,1.9,5,3v18c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V3C19,1.9,18.1,1.01,17,1.01z M17,18H7V6h10V18z M9.5,8.5H12V7H8v4h1.5V8.5z M12,17h4v-4h-1.5v2.5H12V17z" /></g></g></svg>}
                    {svgName == 'timing' && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M16.5 12.5H15v4l3 2 .75-1.23-2.25-1.52V12.5zM16 9L2 3v7l9 2-9 2v7l7.27-3.11C10.09 20.83 12.79 23 16 23c3.86 0 7-3.14 7-7s-3.14-7-7-7zm0 12c-2.75 0-4.98-2.22-5-4.97v-.07c.02-2.74 2.25-4.97 5-4.97 2.76 0 5 2.24 5 5S18.76 21 16 21z" /></svg>}
                    <LabelSmall style={{ textAlign: 'center', color }}>{text}</LabelSmall>
                </div>
            </> :
                <div style={{
                    position: 'relative',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    {index == 0 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none" /><path d="M22 12l-4 4-1.41-1.41L18.17 13h-5.23c-.34 3.1-2.26 5.72-4.94 7.05C7.96 21.69 6.64 23 5 23c-1.66 0-3-1.34-3-3s1.34-3 3-3c.95 0 1.78.45 2.33 1.14 1.9-1.03 3.26-2.91 3.58-5.14h-3.1C7.4 14.16 6.3 15 5 15c-1.66 0-3-1.34-3-3s1.34-3 3-3c1.3 0 2.4.84 2.82 2h3.1c-.32-2.23-1.69-4.1-3.59-5.14C6.78 6.55 5.95 7 5 7 3.34 7 2 5.66 2 4s1.34-3 3-3c1.64 0 2.96 1.31 2.99 2.95 2.68 1.33 4.6 3.95 4.94 7.05h5.23l-1.58-1.59L18 8l4 4z" /></svg>}
                    {index == 1 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><circle cx="10" cy="8" r="4" /><path d="M10.67,13.02C10.45,13.01,10.23,13,10,13c-2.42,0-4.68,0.67-6.61,1.82C2.51,15.34,2,16.32,2,17.35V20h9.26 C10.47,18.87,10,17.49,10,16C10,14.93,10.25,13.93,10.67,13.02z" /><path d="M20.75,16c0-0.22-0.03-0.42-0.06-0.63l1.14-1.01l-1-1.73l-1.45,0.49c-0.32-0.27-0.68-0.48-1.08-0.63L18,11h-2l-0.3,1.49 c-0.4,0.15-0.76,0.36-1.08,0.63l-1.45-0.49l-1,1.73l1.14,1.01c-0.03,0.21-0.06,0.41-0.06,0.63s0.03,0.42,0.06,0.63l-1.14,1.01 l1,1.73l1.45-0.49c0.32,0.27,0.68,0.48,1.08,0.63L16,21h2l0.3-1.49c0.4-0.15,0.76-0.36,1.08-0.63l1.45,0.49l1-1.73l-1.14-1.01 C20.72,16.42,20.75,16.22,20.75,16z M17,18c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S18.1,18,17,18z" /></g></g></svg>}
                    {index == 2 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><rect fill="none" height="24" width="24" /><path d="M19,3H4.99C3.89,3,3,3.9,3,5l0.01,14c0,1.1,0.89,2,1.99,2h10l6-6V5C21,3.9,20.1,3,19,3z M7,8h10v2H7V8z M12,14H7v-2h5V14z M14,19.5V14h5.5L14,19.5z" /></svg>}
                    {index == 3 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" /></svg>}
                    {index == 4 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M16.5 12.5H15v4l3 2 .75-1.23-2.25-1.52V12.5zM16 9L2 3v7l9 2-9 2v7l7.27-3.11C10.09 20.83 12.79 23 16 23c3.86 0 7-3.14 7-7s-3.14-7-7-7zm0 12c-2.75 0-4.98-2.22-5-4.97v-.07c.02-2.74 2.25-4.97 5-4.97 2.76 0 5 2.24 5 5S18.76 21 16 21z" /></svg>}
                    {index == 5 && <svg style={svgStyle} xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" fill={color} width="18px" height="18px"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><g><path d="M17,1.01L7,1C5.9,1,5,1.9,5,3v18c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V3C19,1.9,18.1,1.01,17,1.01z M17,18H7V6h10V18z M9.5,8.5H12V7H8v4h1.5V8.5z M12,17h4v-4h-1.5v2.5H12V17z" /></g></g></svg>}
                    <LabelSmall style={{ textAlign: 'center', color }}>{text}</LabelSmall>
                </div>
            }
        </div>
    )
};
export default Step;